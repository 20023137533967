import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { ModelItemInterface, ModelService } from '@proman/services/model.service';
import { CorporateNamespace, User, UserMenu } from '@proman/interfaces/entity-interfaces';
import { Store } from '@ngrx/store';
import { CurrUser } from '@proman/interfaces/object-interfaces';
import { getCurrUser, loadMinimalMenu } from '@proman/store/curr-user';
import { Entity } from '@proman/services/entity.service';
import { filter } from 'rxjs/operators';
import { getPublicSystemOptions } from '@proman/store/system-options';

@Component({
    selector: 'pm-add-user-menu-item',
    template: `
        <pro-dialog-title title="add_menu_item"></pro-dialog-title>
        <div mat-dialog-content fxLayout="column">
           <pm-txt [value]="item.name"
                   [config]="{ label: 'name' }"
                   (onChange)="model.update('name', $event)"></pm-txt>
           <pm-txt [value]="item.description"
                   [config]="{ label: 'description' }"
                   (onChange)="model.update('description', $event)"></pm-txt>
            <pro-checkbox [value]="addingGroup"
                          [config]="{ label: 'group' }"
                          (onChange)="addingGroup = $event"></pro-checkbox>
            @if (!addingGroup) {
                @if (isCorp) {
                    <div fxLayout="row" id="corpUrl">
                        <div class="Editable-Label">Url: &nbsp;</div>
                        <pro-select fxFlex="300px" [value]="selectedNamespace" [options]="namespaces" (onChange)="selectedNamespace = $event" [config]="{}"></pro-select>
                        <div>{{ item.state.replace(namespaces[0].name, selectedNamespace.name) }}</div>
                    </div>
                } @else {
                    <pro-editable-container label="url">
                        {{ item.state }}
                    </pro-editable-container>
                }
            }

            <pm-txt [value]="item.priority"
                    [config]="{ label: 'priority', parseNumber: true, validators: { number: true } }"
                    (onChange)="model.update('priority', $event)"></pm-txt>
            <pm-icon-select [value]="item.icon"
                            (onChange)="model.update('icon', $event)"></pm-icon-select>
            <pro-color [value]="item.color"
                      [config]="{ label: 'color' }"
                      (onChange)="model.update('color', $event)"></pro-color>
            @if (!addingGroup) {
                <pro-autoc [value]="item.group"
                           [config]="{ entity: 'user_menu_group' }"
                           (onChange)="model.update('group', $event.id)"></pro-autoc>
            }
        </div>
        <pro-dialog-actions (callback)="confirm()"
                           [isCallbackDisabled]="!(item.name)"
                           [isCallbackHidden]="!isCreate"
                           [variant]="isCreate ? 'create' : undefined"
                           variant="confirm"></pro-dialog-actions>    `,
    styles: [`
        #corpUrl div {
            align-content: center;
        }
    `]
})

export class AddUserMenuItemDialogComponent {
    model: ModelItemInterface;
    item: Partial<UserMenu>;
    isCreate: boolean;
    currUser: CurrUser;
    isCorp: boolean;
    namespaces: CorporateNamespace[];
    selectedNamespace: CorporateNamespace;
    addingGroup: boolean;

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: { item?: UserMenu },
        private Model: ModelService,
        private Entity: Entity,
        private dialogRef: MatLegacyDialogRef<AddUserMenuItemDialogComponent>,
        private store: Store,
    ) {
        this.item = this.data.item || {
            state: window.location.href,
            priority: 0,
            description: '-',
        };
        this.model = this.Model.get(this.item, 'user_menu');

        this.isCreate = !this.item.id;

        this.store.select(getCurrUser).pipe(filter((val) => !!val))
            .subscribe((value) => {
                this.currUser = value;
            });

        this.store.select(getPublicSystemOptions).pipe(filter((val) => !!val)).subscribe((opts) => {
            this.isCorp = opts.corporate;
            if (this.isCorp) {
                this.namespaces = [{ id: 0, name: opts.namespace, children: [] }, ...opts.namespaces];
                this.selectedNamespace = this.namespaces[0];
            }
        })
    }

    confirm() {
        if (this.isCorp) {
            this.item.state = this.item.state.replace(this.namespaces[0].name, this.selectedNamespace.name)
        }

        if (this.addingGroup) {
            delete this.item.state;
            delete this.item.group;
        }

        this.Entity.get('user')
            .get({ 'person.id': this.currUser.person.id, select: ['id'] })
            .then((user: User) => {
                this.Entity.get(this.addingGroup ? 'user_menu_group' : 'user_menu')
                    .create({ ...this.item, user: user.id })
                    .then(() => {
                        this.dialogRef.close();
                        this.store.dispatch(loadMinimalMenu());
                    });
            });
    }
}
