import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Entity } from '@proman/services/entity.service';

@Component({
    selector: 'pm-workshifts-colors-dialog',
    template: `
        <pro-dialog-title title="colors"></pro-dialog-title>
        <div mat-dialog-content>
            <pm-workshifts-colors></pm-workshifts-colors>
        </div>
        <pro-dialog-actions></pro-dialog-actions>
    `
})

export class WorkshiftColorsDialogComponent {

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        private Entity: Entity,
        private dialogRef: MatLegacyDialogRef<WorkshiftColorsDialogComponent>,
    ) {

    }

}
