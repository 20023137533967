import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';

@Component({
    selector: 'pm-workplace-info',
    template: `
        <div mat-dialog-actions>
            <h3>
                {{ data.workplace.name }}</h3>
            <div>
                {{ data.workplace.comments }}</div>
        </div>
    `
})
export class WorkplaceInfoDialogComponent {

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        public dialogRef: MatLegacyDialogRef<WorkplaceInfoDialogComponent>
    ) {

    }

}
