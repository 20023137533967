import { Component, Inject, OnInit } from '@angular/core';
import { Entity } from '@proman/services/entity.service';
import { Material, Supplier } from '@proman/interfaces/entity-interfaces';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { safeMapId } from '@proman/utils';
import { MaterialEntityInterface } from '@proman/resources/material';

@Component({
    selector: 'pm-supplier-remove-dialog',
    template: `
        <pro-dialog-title title="remove_supplier"></pro-dialog-title>
        <div mat-dialog-content fxLayout="column">
            <p>{{ 'delete' | translate }} {{ supplier.name }} ? </p>

            <ng-container *ngIf="hasMaterials?.length">
                <pro-autoc [value]="newSupplier"
                          [config]="{ label: 'substitute_supplier', entity: 'supplier', entityParams: { id: '!' + this.supplier.id } }"
                          (onChange)="newSupplier = $event"></pro-autoc>
            </ng-container>
        </div>

        <pro-dialog-actions (callback)="confirm()"
                           variant="confirm"></pro-dialog-actions>
    `
})

export class SupplierRemoveDialogComponent implements OnInit {
    hasMaterials: Material[];
    supplier: Supplier;
    newSupplier: Supplier;
    materialEntity: MaterialEntityInterface;

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: { item: Supplier },
        private Entity: Entity,
        private dialogRef: MatLegacyDialogRef<SupplierRemoveDialogComponent>
    ) {
            this.supplier = this.data.item;
            this.materialEntity = this.Entity.get('material');
    }

    ngOnInit(): void {
        this.materialEntity
            .search({ 'suppliers.id': this.supplier.id })
            .then((response: Material[]) => this.hasMaterials = response);
    }

    confirm() {
        const deleteCallback = () => this.Entity.get('supplier')
            .remove({id: this.supplier.id})
            .then(() => this.dialogRef.close(true))

        if (this.hasMaterials && this.newSupplier) {
            this.materialEntity
                .addSupplier({materialIds: safeMapId(this.hasMaterials), supplierId: this.newSupplier.id})
                .then(() => deleteCallback());
        } else {
            deleteCallback();
        }



    }

}
