import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Entity, EntityInterface } from '@proman/services/entity.service';
import { ProductionOperationService } from '../../products/services/production-operation.service';
import { ACL } from '@proman/services/acl.service';
import { Dialog } from '@frontend/shared/services/dialog.service';
import { FilterService } from '@proman/services/filter.service';
import { findById, utcFormat } from '@proman/utils';
import moment from 'moment';
import { CursorLoadingService } from '@proman/services/cursor-loading.service';
import { CurrUser } from '@proman/interfaces/object-interfaces';
import { Employee, Workplace, WorkplaceEmployee } from '@proman/interfaces/entity-interfaces';
import { WorkplaceEntityInterface } from '@proman/resources/workplace';
import { ProductionOperationEntityInterface } from '@proman/resources/production_operation';
import { EmployeeEntityInterface } from '@proman/resources/employee';
import { Store } from '@ngrx/store';
import { getCurrUser } from '@proman/store/curr-user';
import { ToastService } from '@proman/services/toast.service';
import { InlineListService } from '@proman/inline-list/inline-list.service';

@Component({
    selector: 'pm-event-edit-dialog',
    template: `
        <pro-dialog-title title="operation_settings"></pro-dialog-title>
        <div mat-dialog-content>
            <div fxLayout="column"
                 fxFlex="500px">
                <pro-label>{{ 'options' | translate }}</pro-label>
                <div *ngIf="booking.type == 'workplace'"
                     fxLayout="column">
                    <div fxLayout="row" fxFlex>
                        <pro-autoc [value]="resource"
                                  [config]="{ label: 'workplace', cache: false, getOptionName: getWorkplaceName, entity: 'workplace', entityParams: { 'articleOperationWorkplaces.articleOperation.id': event.articleOperation.id } }"
                                  (onChange)="updateResource($event)"
                                  fxFlex="250px"></pro-autoc>

                        <pro-btn (onClick)="updateResource(null)"
                                icon="times"
                                [tooltip]="'remove_resource' | translate"
                                theme="warn"
                                [ngClass]="{ 'VisibilityHidden': !resource }"></pro-btn>

                        <pro-checkbox [value]="autoPlaning"
                                     (onChange)="autoPlaning = !autoPlaning"
                                     [config]="{ label: 'auto_planing' }"
                                     pmPadding></pro-checkbox>
                    </div>
                    <div fxLayout="row"
                         fxLayoutAlign="space-between end"
                         class="event-time-row"
                         fxFlex="noshrink">
                        <pro-datepicker [value]="booking.plannedStart"
                                       (onChange)="updateTime('plannedStart', $event)"
                                       [config]="{ label: 'planned_start', emitTimeZoneValue: false }"
                                       [disabled]="!permissions.canUpdateTime"
                                       fxFlex="135px"></pro-datepicker>
                        <pro-datepicker [value]="booking.plannedEnd"
                                       (onChange)="updateTime('plannedEnd', $event)"
                                       [config]="{ label: 'planned_end', emitTimeZoneValue: false }"
                                       [disabled]="!permissions.canUpdateTime"
                                       fxFlex="135px"></pro-datepicker>
                        <pm-time-interval [value]="booking.duration"
                                          [config]="{ label: 'duration', visibility: { hours: true, minutes: true, seconds: false }, fixAlignMargin: true }"
                                          (onChange)="updateTime('duration', $event)"
                                          [disabled]="!permissions.canUpdateTime"></pm-time-interval>
                    </div>
                    <div class="List">
                        <div *ngIf="booking.workplace"
                             class="List-header"
                             fxLayout="row"
                             fxLayoutAlign="start center">
                            <pro-label>{{ 'employees' | translate }}</pro-label>
                            <pro-btn
                                    (click)="addEmployee($event)"
                                    icon="plus"
                                    [tooltip]="'add_employee' | translate"
                                    theme="accent"></pro-btn>
                        </div>
                        <mat-accordion>
                            <mat-expansion-panel *ngFor="let childBooking of booking.childBookings"
                                                 [pmEventEmployeeTimeRange]="childBooking">

                                <mat-expansion-panel-header>
                                    <mat-panel-description>
                                        <div fxLayout="row"
                                             fxFlex="noshrink"
                                             fxLayoutAlign="space-between center">
                                            <div fxLayout="column">
                                                <strong>{{ childBooking.employee?.name }}</strong>
                                                <small>{{ childBooking._time }}</small>
                                            </div>
                                            <pro-btn
                                                    (click)="removeEmployee(childBooking)"
                                                    icon="times"
                                                    [tooltip]="'remove_employee' | translate"
                                                    theme="warn"
                                                    proClickStopPropagation></pro-btn>
                                        </div>
                                    </mat-panel-description>
                                </mat-expansion-panel-header>

                                <div fxLayout="column">
                                    <pro-autoc [value]="childBooking.employee"
                                              [config]="{ label: 'employee', cache: false }"
                                              [options]="options.employeeOptions"
                                              (onSearch)="getEmployees(childBooking.employee)"
                                              (onChange)="editEmployeeBooking(childBooking, 'employee', $event.employee)"></pro-autoc>
                                    <div fxLayout="row"
                                         fxLayoutAlign="space-between center">
                                        <pro-datepicker [value]="childBooking.plannedStart"
                                                       (onChange)="editEmployeeBooking(childBooking, 'plannedStart', $event)"
                                                       [config]="{ label: 'planned_start' }"
                                                       fxFlex="135px"></pro-datepicker>
                                        <pro-datepicker [value]="childBooking.plannedEnd"
                                                       (onChange)="editEmployeeBooking(childBooking, 'plannedEnd', $event)"
                                                       [config]="{ label: 'planned_end' }"
                                                       fxFlex="135px"></pro-datepicker>
                                    </div>
                                </div>

                            </mat-expansion-panel>
                        </mat-accordion>

                    </div>
                </div>
                <div fxLayout="column"
                     *ngIf="booking.type == 'employee'">
                    <pro-autoc [value]="resource"
                              [config]="{ label: 'employee', cache: false }"
                              [options]="options.employeeOptions"
                              (onSearch)="getEmployees()"
                              (onChange)="set('resource', $event)"></pro-autoc>
                </div>
                <div fxLayout="column"
                     *ngIf="booking.type == 'subcontractor'">
                    <pro-autoc [value]="resource"
                              [config]="{ label: 'subcontractor', cache: false }"
                              [options]="options.subcontractorOptions"
                              (onSearch)="getSubcontractors()"
                              (onChange)="set('resource', $event)"></pro-autoc>
                </div>
                <pro-checkbox [value]="event.qualityControl"
                           (onChange)="updateSupervised($event)"
                           [config]="{ label: 'quality_control' }"
                           [disabled]="!permissions.canUpdateSupervisor || !this.ACL.checkOne(['event.confirm'])"></pro-checkbox>
                <pro-autoc *ngIf="event.qualityControl"
                          [value]="event.supervisor"
                          [config]="{ label: 'superviser', cache: false }"
                          [getOptions]="getSupervisers"
                          (onChange)="updateSuperviser($event)"
                          [disabled]="!permissions.canUpdateSupervisor || !this.ACL.checkOne(['event.confirm'])"></pro-autoc>
            </div>
            <div fxFlex="300px"
                 fxLayout="column"
                 class="LeftMargin"
                 *ngIf="event.previousEventLinks?.length && permissions.canUpdateTime">
                <pro-label>{{ 'links' | translate }}</pro-label>
                <mat-accordion [multi]="true">

                    <mat-expansion-panel *ngFor="let link of event.previousEventLinks; let $index = index">
                        <mat-expansion-panel-header>
                            <div fxLayout="row"
                                 fxLayoutAlign="start center">
                                <mat-panel-description>{{ link.previousEvent.articleOperation.operation.name }}</mat-panel-description>
                                <div fxFlex></div>
                                <pro-btn
                                        (click)="removeLink(link, $index)"
                                        icon="trash"
                                        [tooltip]="'remove_link' | translate"
                                        theme="warn"></pro-btn>
                            </div>
                        </mat-expansion-panel-header>

                        <pm-txt [config]="{ label: 'link_min', validators: { number: true } }"
                                [value]="link.min"
                                (onChange)="updateLink(link, 'min', $event)"></pm-txt>
                        <pm-txt [config]="{ label: 'link_max', validators: { number: true } }"
                                [value]="link.max"
                                (onChange)="updateLink(link, 'max', $event)"></pm-txt>
                        <pm-txt [config]="{ label: 'link_overlap', validators: { number: true } }"
                                [value]="link.overlap"
                                (onChange)="updateLink(link, 'overlap', $event)"></pm-txt>
                    </mat-expansion-panel>

                </mat-accordion>
            </div>
        </div>
        <pro-dialog-actions></pro-dialog-actions>
    `,
    styles: ['.event-time-row { min-height: 60px; }']
})

export class EventEditDialogComponent implements OnInit {
    event: any;
    booking: any;
    currUser: CurrUser;
    permissions: any;
    employeeId: number;
    entities: {
        workplace?: WorkplaceEntityInterface;
        production_operation?: ProductionOperationEntityInterface;
        employee?: EmployeeEntityInterface;
        [key: string]: EntityInterface;
    } = {};
    resource: Workplace;
    subcontractors: any;
    options: any = {};
    autoPlaning: boolean = false;

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        private store: Store,
        public dialogRef: MatLegacyDialogRef<EventEditDialogComponent>,
        private Entity: Entity,
        public ACL: ACL,
        private Dialog: Dialog,
        private filter: FilterService,
        private inlineList: InlineListService,
        private Toast: ToastService,
        private productionOperationService: ProductionOperationService,
        private CursorLoading: CursorLoadingService,
    ) {
        this.entities['employee'] = this.Entity.get('employee');
        this.entities['event_link'] = this.Entity.get('event_link');
        this.entities['production_operation'] = this.Entity.get('production_operation');
        this.entities['workplace_employee'] = this.Entity.get('workplace_employee');
        this.entities['workplace'] = this.Entity.get('workplace');
        this.entities['subcontractor'] = this.Entity.get('subcontractor');
        this.entities['resource_booking'] = this.Entity.get('resource_booking');
        this.entities['article_operation'] = this.Entity.get('article_operation');

        this.store.select(getCurrUser)
            .subscribe((value) => {
                this.currUser = value;
                this.employeeId = this.currUser?.type === 'employee' && this.currUser?.person.id || null;
            });

        this.event = this.data.event;
        this.booking = this.data.booking || this.event.selectedBooking || this.event.resourceBookings[0];

        switch (this.booking.type) {

            case 'workplace':
                this.resource = this.booking.workplace;
                break;

            case 'employee':
                this.resource = this.booking.employee;
                break;

            case 'subcontractor':
                this.resource = this.booking.subcontractor;
                break;
        }

        this.booking.duration = this.getDuration(this.booking);

        this.checkEventActions();

        if (!this.event.previousEventLinks) {
            this.entities['event_link'].QB.aggregate()
                .search({ 'nextEvent.id': this.event.id })
                .then((response: any) => this.event.previousEventLinks = response);
        }

    }

    ngOnInit() {
        this.refreshEmployees();
        if (this.booking.workplace) this.getEmployees();
    }

    checkEventActions() {
        this.permissions = {
            canUpdateWorkplace: this.productionOperationService.canUpdateWorkplace(),
            canUpdateEmployees: this.productionOperationService.canUpdateEmployees(),
            canUpdateTime: this.productionOperationService.canUpdateTime(),
            canUpdateSupervisor: this.productionOperationService.canUpdateSupervisor(),
            canUpdateFiles: this.productionOperationService.canUpdateFiles(this.event),
            canUpdateItemQuantities: this.productionOperationService.canUpdateItemQuantities(this.event),
            canUpdateParameters: this.productionOperationService.canUpdateParameters(this.event, this.employeeId),
            canStart: this.productionOperationService.canStart(this.event, this.event.production),
            canEnd: this.productionOperationService.canEnd(this.event),
            canCancel: this.productionOperationService.canCancel(this.event),
            canConfirm: this.productionOperationService.canConfirm(this.event, this.employeeId),
            canComment: this.productionOperationService.canComment(),
            showComments: this.productionOperationService.showComments(this.event),
            showItemQuantities: this.productionOperationService.showItemQuantities(this.event),
            showOptions: this.productionOperationService.showOptions(),
            canUpdateProductionColor: this.ACL.check('production.edit'),
            canEdit: this.ACL.check('event.edit')
        };
    }

    getEmployees = (employee?: Employee): Promise<WorkplaceEmployee[]> => {
        return this.options.employeeOptions = this.entities['employee']
            .getIdleProductionEmployees({
                workplaceId: this.booking.workplace.id,
                start: this.event.plannedStart,
                end: this.event.plannedEnd
            })
            .then((idleEmployees: any) => {
                return this.entities['workplace_employee']
                    .search({
                        'workplace.id': this.booking.workplace.id,
                        'disabled': false,
                        'join': ['employee']
                    })
                    .then((workplaceEmployees: any) => {
                        if (employee) { // if selecting employee to change, do not include him into that list
                            workplaceEmployees = workplaceEmployees.filter((item: any) => item.employee.id !== employee.id);
                        }

                        workplaceEmployees.forEach((employee: any) => {
                            employee.name = employee.employee.name;

                            if (!findById(idleEmployees, employee.employee)) {
                                employee.name += ' (' + this.filter.translate('occupied') + ')';
                                employee.busy = true;
                            }

                            return employee;
                        });

                        return workplaceEmployees;

                    });
            });
    };

    refreshEmployees = () => {
        this.entities['resource_booking']
            .search({
                'parentBooking.id': this.booking.id,
                'join': ['employee']
            })
            .then((response: any) => this.booking.childBookings = response);
    };

    updateSuperviser = (value: any) => {
        value = value || { id: null };

        this.event.supervisor = value;

        return this.entities['production_operation'].update({ id: this.event.id, supervisor: value.id });
    };

    getSupervisers = () => {
        this.CursorLoading.start();

        return this.entities['article_operation']
            .get({ id: this.event.articleOperation.id, join: ['supervisor'] })
            .then((response: any) => {

                if (response.supervisor) {
                    return this.entities['employee']
                        .search({
                            'specialisations.id': response.supervisor.id,
                            'join': ['specialisations']
                        })
                        .then((response2: any) => {
                            this.CursorLoading.stop();

                            return response2;
                        });
                }

            });
    };

    addEmployee = ($event: any) => {
        this.getEmployees()
            .then((data) => {
                this.inlineList.show({
                    event: $event,
                    data,
                    onSelect: (employee: any) => {

                        this.entities['resource_booking']
                            .create({
                                event: this.event.id,
                                employee: employee.employee.id,
                                parentBooking: this.booking.id,
                                plannedStart: this.booking.plannedStart,
                                plannedEnd: this.booking.plannedEnd
                            })
                            .then(this.refreshEmployees);
                    }
                });
            });
    };

    removeEmployee = (eventEmployee: any) => {
        this.entities['resource_booking']
            .remove({ id: eventEmployee.id })
            .then(this.refreshEmployees);
    };

    updateLink = (link: any, field: any, value: any) => {
        let data = { id: link.id };

        link[field] = value;
        data[field] = value;

        this.entities['event_link'].update(data);
    };

    removeLink = (link: any, $index: any) => {

        this.entities['event_link']
            .remove({ id: link.id })
            .then(() => this.event.previousEventLinks.splice($index, 1));

    };

    updateDuration = (value: any) => {
        let start = moment(this.booking.plannedStart);
        let end = moment(start).add(value, 's');
        let endStr = moment(end).format();
        let oldEndStr = this.booking.plannedEnd;

        this.booking.plannedEnd = endStr;

        this.entities['production_operation']
            .updateBookings({
                bookings: [{
                    bookingId: this.booking.id,
                    plannedStart: start.format(),
                    plannedEnd: endStr
                }], ignoreResourceErrors: false
            })
            .then(null)
            .catch((error: any) => {
                this.booking.plannedEnd = oldEndStr;
                this.Toast.pop('error', 'error.' + error.message);
            });
    };

    getBookingRequest = (booking: any, data: any) => {
        return {
            bookings: [{
                bookingId: booking.id,
                plannedStart: data.plannedStart,
                plannedEnd: data.plannedEnd,
                resourceId: this.resource ? this.resource.id : null,
                autoPlaning: this.autoPlaning
            }],
            ignoreResourceErrors: false
        };
    };

    getDuration = (booking: any) => {
        return moment.duration(moment(booking.plannedEnd).diff(moment(booking.plannedStart))).asSeconds()
    };

    showError = (error: any) => {
        this.Toast.pop('error', 'error.' + error.message);
    };

    handleTimeUpdate = (data: any) => {

        for (let key in data) {
            this.booking[key] = data[key];
        }

        this.setDuration(this.getDuration(this.booking));
    };

    updateTime = (property: string, value: any) => {
        let booking = this.booking;
        let oldData: any = { plannedStart: booking.plannedStart, plannedEnd: booking.plannedEnd };
        let data: any = {};

        if (property === 'plannedStart') {
            data.plannedStart = value;
            data.plannedEnd = utcFormat(moment(value).add(booking.duration, 's'));

        } else if (property === 'plannedEnd') {
            data.plannedEnd = value;
            data.plannedStart = utcFormat(moment(value).add(- booking.duration, 's'));

        } else if (property === 'duration') {
            data.plannedStart = utcFormat(moment(booking.plannedStart));
            data.plannedEnd = utcFormat(moment(data.plannedStart).add(value, 's'));

        }

        this.entities['production_operation']
            .updateBookings(this.getBookingRequest(booking, data))
            .then(() => this.handleTimeUpdate(data))
            .catch((error: any) => {
                this.handleTimeUpdate(oldData);
                this.showError(error);
            });
    };

    getWorkplaceName = (workplace: Workplace) => `${workplace.alias} - ${workplace.name}`;

    getSubcontractors = () => {

        if (!this.subcontractors) {
            this.subcontractors = [];

            this.options.subcontractorOptions = this.entities['subcontractor']
                .search({ 'articleOperations.id': this.event.articleOperation.id });

        }
    };

    updateResource = (value: Workplace|null) => {
        this.entities['production_operation']
            .updateBookings({
                bookings: [
                    {
                        bookingId: this.booking.id,
                        plannedStart: this.booking.plannedStart,
                        resourceId: value && value.id || 'false',
                        plannedEnd: this.booking.plannedEnd,
                        autoPlaning: this.autoPlaning
                    }
                ]
            })
            .then(() => {
                this.resource = value;

                if (this.booking.type === 'workplace') {
                    this.booking.workplace = value;
                }

                this.refreshEmployees();
            })
            .catch((error: any) => this.Toast.pop('error', 'error.' + error.message));
    };

    updateSupervised = (value: any) => {
        return this.entities['production_operation']
            .update({ id: this.event.id, qualityControl: value })
            .then(() => {
                this.event.qualityControl = value;
                this.entities['production_operation']
                    .get({ id: this.event.id, join: ['supervisor'] })
                    .then((response: any) => this.event.supervisor = response.supervisor);
            });
    };

    setDuration = (value: any) => {
        this.booking.duration = value;
    };

    set(property: string, value: any) {
        this[property] = value;
    }

    editEmployeeBooking(booking: any, property: string, value: any) {
        let data: any = { id: booking.id };
        let invertedProperty: string = property === 'plannedStart' ? 'plannedEnd' : 'plannedStart';
        let duration: any = this.getDuration(booking);

        if (property === 'plannedStart' && value > booking[invertedProperty]) {
            booking[invertedProperty] = utcFormat(moment(booking[invertedProperty]).add(duration, 's'));
            data[invertedProperty] = booking[invertedProperty];

        }

        if (property === 'plannedEnd' && value < booking[invertedProperty]) {
            booking[invertedProperty] = utcFormat(moment(booking[invertedProperty]).add(- duration, 's'));
            data[invertedProperty] = booking[invertedProperty];

        }

        data[property] = value && value.id || value;

        this.entities['resource_booking']
            .update(data)
            .then(() => booking[property] = value);
    }

}
