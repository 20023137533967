import { Component, Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Entity } from '@proman/services/entity.service';
import { roundNumber } from '@proman/utils';
import { Money } from '@proman/interfaces/common.interface';
import {OrderEntityInterface} from "@proman/resources/order";

@Component({
    selector: 'pm-commission-dialog',
    template: `
        <pro-dialog-title [title]="title"></pro-dialog-title>
        <div mat-dialog-content>
            <div fxLayout="row"
                 fxLayoutAlign="start center">
                <pm-txt [config]="{ label: percentage, validators: { number: true }, parseNumber: true }"
                        [value]="newDiscount"
                        (onChange)="setCommission($event)"></pm-txt>
            </div>
            <pm-price *ngIf="type === 'order'"
                      [value]="discountSum"
                      [config]="{ label: 'sum' }"
                      (onChange)="setOrderDiscount($event)"
            ></pm-price>
            <div class="Alert"
                 *ngIf="+newDiscount < 0">
                {{ 'invalid_' + percentage | translate }}
            </div>
        </div>
        <pro-dialog-actions variant="confirm" (callback)="confirm()"></pro-dialog-actions>
    `
})

export class CommissionDialogComponent {
    form: UntypedFormGroup = new UntypedFormGroup({});
    newDiscount: string|number;
    type: string;
    discountSum: any;
    title: string;
    percentage: string;
    orderEntity: OrderEntityInterface;

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        private Entity: Entity,
        public dialogRef: MatLegacyDialogRef<CommissionDialogComponent>
    ) {
        this.type = data.type;
        this.orderEntity = this.Entity.get('order');
        if (this.type === 'order_agent') this.initOrderAgent();
        if (this.type === 'agent') this.initAgent();
        if (this.type === 'order') this.initOrder();
    }

    initAgent() {
        this.title = this.data.agent.name;
        this.percentage = 'commission %'
        this.newDiscount = this.data.agent.commission;
    }

    initOrderAgent() {
        this.title = this.data.order.agent.name;
        this.percentage = 'commission %'
        this.newDiscount = this.data.value;
    }

    initOrder() {
        this.title = this.data.order.customer.name;
        this.percentage = 'discount %'
        let order: any = this.data.order;
        this.discountSum = Object.assign({}, order.discountSum);
        this.newDiscount = order.customerDiscountPercent;

    }

    setCommission(value: any, updateOther: boolean = true) {
        this.newDiscount = Math.abs(value);

        if (this.type === 'order')
            this.discountSum = {
                amount: roundNumber(+this.data.order.customerPrice.amount * this.newDiscount / 100).toString(),
                currency: this.data.order.customerPrice.currency
            } as Money;
    };

    confirm() {

        if (this.type === 'agent') {
            this.Entity.get('agent').update({ id: this.data.agent.id, commission: this.newDiscount })
                .then(() => {
                    this.data.agent.commission = this.newDiscount;
                    this.dialogRef.close(1);
                });

        }

        if (this.type === 'order_agent') {
            this.orderEntity.postName('update_agent_commission_percent')
                ({
                    id: this.data.order.id,
                    agentId: this.data.order.agent.id,
                    commission: this.newDiscount
                })
                .then(() => this.dialogRef.close(1));
        }

        if (this.type === 'order') {
            this.orderEntity.updateDiscountPercent({ id: this.data.order.id, discountPercent: this.newDiscount })
                .then(() => this.dialogRef.close(1));
        }

    }

    setOrderDiscount = (value: Money) => {
        this.discountSum = value;
        this.newDiscount = +this.data.order?.customerPrice?.amount > 0 ?
            roundNumber(+value.amount / (+this.data.order?.customerPrice?.amount) * 100) :
            0;
    };

    updateOrderDiscount = () => {
        return this.orderEntity.update({ id: this.data.order.id, discountSum: this.discountSum });
    };

}
