import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { ImagePathService } from '@proman/services/image-path.service';
import { FilePreviewService } from '@proman/services/file-preview.service';

@Component({
    selector: 'pm-view-pdf-dialog',
    template: `
        <pro-dialog-title title="view_file"></pro-dialog-title>
        <div mat-dialog-content>
        <h3>
            <fa name="check"></fa>
            {{ 'file_created' | translate }}
        </h3>
        </div>
        <pro-dialog-actions (callback)="confirm()"
                           variant="confirm"></pro-dialog-actions>

    `
})

export class ViewPdfDialogComponent {

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        private ImagePath: ImagePathService,
        private FilePreview: FilePreviewService,
        public dialogRef: MatLegacyDialogRef<ViewPdfDialogComponent>,
    ) {
    }

    confirm() {
        // window.open(this.ImagePath.get(this.data.file.path, { path: true }));
        this.FilePreview.show(this.data.file);
    }
}
