import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Entity } from '@proman/services/entity.service';
import { ModelService } from '@proman/services/model.service';
import { ArticleProductionParameter } from '@proman/interfaces/entity-interfaces';

@Component({
    selector: 'pm-product-create-dialog',
    template: `
        <pro-dialog-title title="new_product"></pro-dialog-title>
        <div mat-dialog-content>
            <pm-txt [config]="{ label: 'name', required: true }"
                    [value]="product.name"
                    (onChange)="model.update('name', $event)"></pm-txt>
            <pro-autoc [value]="product.article"
                       [config]="{ label: 'article', entity: 'article', entityParams: { confirmed: true, sort: { name: 'asc' } }, required: true }"
                       (onChange)="model.update('article', $event)"></pro-autoc>
            <pro-parameter *ngFor="let articleProductParameter of articleProductParameters"
                           [parameter]="articleProductParameter"
                           (onChange)="set(articleProductParameter , 'value', $event)"
                           [config]="{}"
                           [disabled]="articleProductParameter.isLocked"
            ></pro-parameter>
        </div>
        <pro-dialog-actions (callback)="create()"
                           variant="create" [isCallbackDisabled]="!(product.name && product.article)"></pro-dialog-actions>
    `
})

export class ProductCreateDialogComponent {
    productEntity: any;
    product: any;
    model: any;
    articleProductParameters: ArticleProductionParameter[];

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        private Entity: Entity,
        public dialogRef: MatLegacyDialogRef<ProductCreateDialogComponent>,
        modelService: ModelService,
    ) {
        this.productEntity = Entity.get('product');
        this.product = { parameters: [] };
        this.model = modelService.get(this.product, this.productEntity);

        this.model.addHandler('article', this.handleArticle);
    }

    handleArticle = (value: any) => {

        if (value) {
            this.Entity.get('article_product_parameter')
                .search({ 'article.id': value.id, 'join': ['parameter'] })
                .then((response: any) => this.articleProductParameters = response);

        }

    };

    create() {
        let product = this.product;
        let articleProductParameters: any = this.articleProductParameters;
        let data: any = {
            name: product.name,
            article: product.article.id,
            parameters: [],
            customer: product.customer
        };

        articleProductParameters?.forEach((articleProductParameter: any) => {
            data.parameters.push({
                parameter: articleProductParameter.parameter.id,
                value: articleProductParameter.value
            });

        });

        this.productEntity
            .create(data)
            .then((response: any) => this.dialogRef.close(response.data));
    }

    set(item: any, property: string, value: any) {
        item[property] = value;
    }

}
