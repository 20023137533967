import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { UserMenu, UserMenuGroup } from '@proman/interfaces/entity-interfaces';
import { Store } from '@ngrx/store';
import { getMinimalMenu } from '@proman/store/curr-user';
import { Entity } from '@proman/services/entity.service';
import { OnEditEmitType, OnRemoveEmitType } from '@proman/list-manager/list-manager.component';
import { Dialog } from '../../shared/services/dialog.service';

@Component({
    selector: 'pm-edit-user-menu-item',
    template: `
        <pro-dialog-title title="select_template"></pro-dialog-title>
        <div mat-dialog-content fxLayout="column">
            @if (grouped && grouped.length > 0) {
                <pro-list-manager [items]="grouped"
                                  [config]="{ noSplice: true, namePath: 'description', aliasPath: 'name' }"
                                  (onEdit)="editGroup($event)"
                                  (onRemove)="handleGroupRemove($event)"></pro-list-manager>
            }
            @if (unassigned && unassigned.length > 0) {
                <pro-list-manager [items]="unassigned"
                                  [config]="{ noSplice: true, namePath: 'description', aliasPath: 'name' }"
                                  (onRemove)="handleRemove($event)"></pro-list-manager>
            }
        </div>
        <pro-dialog-actions></pro-dialog-actions>    `
})

export class EditUserMenuItemDialogComponent {
    unassigned: UserMenu[];
    grouped: UserMenuGroup[];

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: { item?: UserMenuGroup },
        private Entity: Entity,
        private Dialog: Dialog,
        private dialogRef: MatLegacyDialogRef<EditUserMenuItemDialogComponent>,
        private store: Store,
    ) {
        this.store.select(getMinimalMenu)
            .subscribe((value) => {
                if (this.data.item) {
                    this.grouped = [];
                    this.unassigned = this.data.item.items;
                } else {
                    this.grouped = value.groups;
                    this.unassigned = value.unassigned;
                }
            });

    }

    handleRemove(data: OnRemoveEmitType<UserMenu>) {
        this.Entity.get('user_menu')
            .remove({ id: data.item.id })
            .then(() => this.dialogRef.close());
    }

    editGroup(data: OnEditEmitType<UserMenuGroup>) {
        this.dialogRef.close();
        this.Dialog.open(EditUserMenuItemDialogComponent, { item: data.item });
    }

    handleGroupRemove(data: OnRemoveEmitType<UserMenuGroup>) {
        this.Entity.get('user_menu_group')
          .remove({ id: data.item.id })
          .then(() => this.dialogRef.close());
    }

}
