import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Entity } from '@proman/services/entity.service';
import { EchartsNodeGraphConfig, EchartsNodeGraphData } from '@proman/charts/components/echart-node-graph.component';
import { Invoice, Order } from '@proman/interfaces/entity-interfaces';
import { FilterService } from '@proman/services/filter.service';
import { PromanStateService } from '@frontend/shared/services/proman-state.service';

@Component({
  selector: 'pm-menu-tree-dialog',
  template: `
        <pro-dialog-title title="menu_tree_map"></pro-dialog-title>
        <div mat-dialog-content fxLayout="column">
          <pro-echart-node-graph [config]="config" [data]="chartData"></pro-echart-node-graph>
        </div>
        <pro-dialog-actions >

        </pro-dialog-actions>
    `
})

export class MenuTreeDialogComponent {
  config: EchartsNodeGraphConfig
  chartData: EchartsNodeGraphData;
  nameRouteMap: { [key: string]: [string]|[string, Object|number] } = {};


  constructor(
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: { order: Order },
    private Entity: Entity,
    private Filter: FilterService,
    private PromanState: PromanStateService,
    private dialogRef: MatLegacyDialogRef<MenuTreeDialogComponent>,
  ) {

    const getInvoiceName = (inv: Invoice): string => `#${inv.id} - ${inv.date}`;

    this.config = { title: 'menu_tree_map', clickCallback: this.handleClick };

    const orderId = this.data.order.id;

    this.nameRouteMap['Orders'] = ['/orders'];
    this.nameRouteMap['OrderProductions'] = [`/orders/${orderId}/productions`];
    this.nameRouteMap['Invoice'] = [`orders/${orderId}/documents`];

    // categories
    // 0: default
    // 1: order
    // 2: production
    // 3: materials
    // 4: order issues

    let id = 1;
    const generateItem = (name: string, x: number, y: number, category: number = 0) => {
      return { id: id++, name, x, y, category };
    };
    const generateItemInSequance = (name: string, x: number, _y: number, category: number = 0, index: number) => {
      const y = _y + 0.2 * (index +1);
      return { id: id++, name, x, y, category };
    };

    const filterString = (name: string): string => {
      return this.Filter.maxLength(name, 20);
    };

    Promise.all([
      this.Entity.get('order')
        .get({
          id: this.data.order.id,
          join: [
            'shipments',
            'products',
            'products.product',
            'productions',
          ]
        }),
      this.Entity.get('invoice')
        .search({
          search: {
            'records.order.name': orderId,
            'records.order.id': orderId
          }

        })
          .then((invoicesResponse) => {
              let result: Invoice[] = [];
              let ids: number[] = [];

              invoicesResponse.forEach((invoice) => {
                 if (!ids.includes(invoice.id)) {
                     result.push(invoice);
                     ids.push(invoice.id);
                 }
              });

              return result;
          })
    ])
      .then(([order, invoices]) => {

        order.shipments.forEach((shipment) => {
          shipment.name = `#${shipment.id} - ${this.Filter.dateTime(shipment.date)}`;
          this.nameRouteMap[shipment.name] = [`/orders/${order.id}/shipments/${shipment.id}`];
        });

        order.productions.forEach((prod) => {
          this.nameRouteMap[prod.name] = [`/production/${prod.id}`];
        });

        order.products.forEach((op) => {
          this.nameRouteMap[op.product.name] = [`/orders/${order.id}/products`];
        });

        invoices.forEach((inv) => {
          inv.name = getInvoiceName(inv);
          this.nameRouteMap[inv.name] = [`/orders/${order.id}/documents`];
        });

        this.chartData = {
          nodes: [
            generateItem('Orders', -0.25, 0, 1),
            generateItem('OrderProductions', 0.25, -0.5, 2),
            ...order.productions.filter((p, i) => i < 6)
              .map((prod, i) => generateItemInSequance( i < 5 ? filterString(prod.name) : '...', 0.25, -0.5, 2, i )),
            generateItem('Order product tests', 2, -0.5),
            generateItem('Materials used', -1, -0.25, 3),
            generateItem('Production operation', -2, -0.375, 2),
            generateItem('Materials', -2, -0.125, 3),
            generateItem('Products', 0.5, -0.75),
            generateItem('Order products', -3.25, -1, 1),
            ...order.products.filter((op, i) => i < 6)
              .map((op, i) => generateItemInSequance(i < 5 ? filterString(op.product.name) : '...', -3.25, -1, 1, i )),
            generateItem('Articles', 0, -1),
            generateItem('Order shipments', 3, -0.875),
            ...order.shipments.filter((s, i) => i < 6)
              .map((shipment, i) => generateItemInSequance(i < 6 ? filterString(shipment.name) : '...', 3, -0.875, undefined, i )),
            generateItem('Invoice', -4, 0.25),
            ...invoices.filter((inv, i) => i < 6)
              .map((invoice, i) => generateItemInSequance(i < 5 ? filterString(invoice.name) : '...', -4, 0.25, undefined, i)),
            generateItem('Customer', -2, 0.25),
            generateItem('Order logs', 2, 0.25, 1),
            generateItem('Payments', 4, 0.25),
            generateItem('Order issues', 0, 0.5, 4),
            generateItem('Order defect acts', -2, 1, 4),
            generateItem('Order customer claims', 2, 1, 4),
          ],
          links: [
            { source: 'Orders', target: 'OrderProductions' },
            ...order.productions.map((prod) => ({ source: 'OrderProductions', target: filterString(prod.name) })),
            { source: 'Orders', target: 'Order product tests' },
            { source: 'OrderProductions', target: 'Order product tests' },
            { source: 'Orders', target: 'Materials used' },
            { source: 'OrderProductions', target: 'Materials used' },
            { source: 'Materials', target: 'Materials used' },
            { source: 'Production operation', target: 'Materials used' },
            { source: 'Products', target: 'Order product tests' },
            { source: 'Orders', target: 'Order shipments' },
            ...order.shipments.map((shipment) => ({ source: 'Order shipments', target: filterString(shipment.name) })),
            { source: 'Products', target: 'Order shipments' },
            { source: 'Articles', target: 'Order shipments' },
            { source: 'Orders', target: 'Order products' },
            { source: 'Products', target: 'Order products' },
            { source: 'Articles', target: 'Order products' },
            ...order.products.map((op) => ({ source: 'Order products', target: filterString(op.product.name) })),
            { source: 'Orders', target: 'Invoice' },
            ...invoices.map((inv) => ({ source: 'Invoice', target: inv.name })),
            { source: 'Orders', target: 'Customer' },
            { source: 'Orders', target: 'Order logs' },
            { source: 'Orders', target: 'Payments' },
            { source: 'Orders', target: 'Order issues' },
            { source: 'Order issues', target: 'Order defect acts' },
            { source: 'Order issues', target: 'Order customer claims' },

          ],
          categories: [
            { name: 'Default' },
            { name: 'Order' },
            { name: 'Production' },
            { name: 'Material' },
            { name: 'Order issues' },
          ]
        }

      })

  }

  handleClick = (eventData: { data: { name: string }, event: MouseEvent }) => {
    const name = eventData.data.name;
    const isNewTab = eventData.event.ctrlKey || eventData.event.metaKey;

    if (name && this.nameRouteMap[name]) {
      this.PromanState.to(this.nameRouteMap[name][0], this.nameRouteMap[name][1],null, isNewTab);
    }

  };

}
