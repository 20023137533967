import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Entity } from '@proman/services/entity.service';
import { ProductionOperation, ResourceBooking } from '@proman/interfaces/entity-interfaces';
import { ACL } from '@proman/services/acl.service';

@Component({
    selector: 'pm-employee-jobs-parameters-edit',
    template: `
        <pro-dialog-title title="{{ 'operation_parameters' | translate }}"></pro-dialog-title>
        <div mat-dialog-content>
            <ng-container *ngIf="parameters?.length">
                <pro-label>{{ 'operation_parameters' | translate }}</pro-label>
                <pro-parameter *ngFor="let parameter of parameters"
                              [parameter]="parameter"
                              [config]="{}"
                              (onChange)="updateParameterValue(parameter, $event)"
                              [disabled]=getStatus()>
                </pro-parameter>
            </ng-container>

            <ng-container *ngIf="childBookingParams?.parameters?.length">
                <pro-label>{{ 'workplace_parameters' | translate }}</pro-label>
                <pro-parameter *ngFor="let parameter of childBookingParams.parameters"
                              [parameter]="parameter"
                              [config]="{}"
                              (onChange)="updateChildBookingParameter(parameter, $event)"
                              [disabled]=getStatus()>
                </pro-parameter>
            </ng-container>
            <ng-container *ngIf="childBookingParams?.employee?.length">
                <div *ngFor="let employee of childBookingParams.employee">
                    <pro-label>{{ employee?.name }}</pro-label>
                    <pro-parameter *ngFor="let parameter of employee.parameters"
                                  [parameter]="parameter"
                                  [config]="{}"
                                  (onChange)="updateChildBookingParameter(parameter, $event)"
                                  [disabled]=getStatus()>
                    </pro-parameter>
                </div>
            </ng-container>
        </div>
        <pro-dialog-actions></pro-dialog-actions>
    `
})

export class EmployeeJobParametersEditDialogComponent {
    parameters: any;
    childBookingParams: any = [];
    operation: any;
    operationName: string;

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        private Entity: Entity,
        private ACL: ACL,
        private dialogRef: MatLegacyDialogRef<EmployeeJobParametersEditDialogComponent>,
    ) {
        if (data.operation) {
            this.operation = data.operation;
            this.parameters = data.operation.productionOperationParameters;
            data.operation.resourceBookings.forEach((booking: any) => this.showParameters(booking));
        } else {
            this.operation = data.resourceBooking;
            this.showParameters(data.resourceBooking);
        }
    }

    showParameters(booking: ResourceBooking) {
        this.childBookingParams.parameters = [];
        this.childBookingParams.employee = [];
        if (booking?.childBookings) booking.childBookings.forEach((childBooking: any) => {
            if (childBooking.parameters) {
                this.childBookingParams.employee.push({
                    'name': childBooking.employee?.name ?? '',
                    'parameters': childBooking.parameters
                });
            }
        });

        if (booking?.parameters) this.childBookingParams.parameters = booking.parameters;
    }

    updateParameterValue(parameter: any, value: any) {
        this.Entity
             .get('production_operation_parameter')
            .update({ id: parameter.id, value })
            .then(() => parameter.value = value);
    };

    updateChildBookingParameter(parameter: any, value: any) {
        this.Entity
             .get('resource_booking_parameter')
            .update({ id: parameter.id, value })
            .then(() => parameter.value = value);
    };

    getStatus() {
        return (this.operation && (this.operation.status === 'finished') || (this.operation.status === 'unstarted')) && !this.ACL.check('production.update_status');
    }
}
