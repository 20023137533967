import { NgModule } from '@angular/core';
import { PromanCommonComponentsModule } from '@proman/common-components/proman-common-components.module';
import { InputsModule } from '../inputs/inputs.module';
import { PipesModule } from '@proman/shared/pipes/pipes.module';
import { PrintTemplateDialogComponent } from '@frontend/shared/components/print-template-dialog.component';
import { InputDialogComponent } from '@frontend/shared/components/input-dialog.component';
import { ViewPdfDialogComponent } from '../orders/components/view-pdf-dialog.component';
import { EntityCreateDialogComponent } from '@frontend/shared/components/entity-create-dialog.component';
import { EntityRemoveDialogComponent } from '@frontend/shared/components/entity-remove-dialog.component';
import { EntityEditDialogComponent } from '@frontend/shared/components/entity-edit-dialog.component';
import { ActivityLogDialogComponent } from '@frontend/shared/components/activity-log-dialog.component';
import { WarehouseEditDialogComponent } from '../workplaces/components/warehouse-edit-dialog.component';
import { CommissionDialogComponent } from '../employees/components/commission-dialog.component';
import { ProductStorageInfoDialogComponent } from '../events/components/product-storage-info-dialog.component';
import { EmployeeDocumentsReadDialogComponent } from '../employees/components/employee-documents-read-dialog.component';
import { BarcodePrinterPrintDialogComponent } from '../printers/components/barcode-printer-print-dialog.component';
import { TextPreviewDialogComponent } from '@frontend/shared/components/text-preview-dialog.component';
import { MaterialCreditMaterialsDialogComponent } from '../materials/components/material-credit-materials-dialog.component';
import { EmployeeJobParametersEditDialogComponent } from '../employees/components/employee-job-parameters-edit-dialog.component';
import { ProductContainerRegisterDialogComponent } from '../products/components/product-container-register-dialog.component';
import { WorkshiftColorsDialogComponent } from '../accounting/components/workshift-colors-dialog.component';
import { MetadataPathBuilderDialogComponent } from '@frontend/shared/components/metadata-path-builder-dialog.component';
import { ConsumerBookingDialogComponent } from '../orders/components/consumer-booking-dialog.component';
import { WorkplaceInfoDialogComponent } from '../workplaces/components/workplace-info-dialog.component';
import { WorkgroupSpecialisationRemoveDialogComponent } from '../workplaces/components/workgroup-specialisation-remove-dialog.component';
import { ArticleOperationWorkplaceSelectDialogComponent } from '../workplaces/components/article-operation-workplace-select-dialog.component';
import { ProductEditDialogComponent } from '../products/components/product-edit-dialog.component';
import { OrderCopyDialogComponent } from '../orders/components/order-copy-dialog.component';
import { EventEditDialogComponent } from '../events/components/event-edit-dialog.component';
import { SaleEventDialogComponent } from '../projects-sale-events/components/sale-event-dialog.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { FrontendListManagerModule } from '../list-manager/frontend-list-manager.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { MaterialQuantSearchDialogComponent } from '../materials/components/material-quant-search-dialog.component';
import { MatLegacyListModule } from '@angular/material/legacy-list';
import { ProductCreateDialogComponent } from '../products/components/product-create-dialog.component';
import { OcrImageReadDialogComponent } from '../orders/components/ocr-image-read.dialog.component';
import { ProductSearchDialogComponent } from '../products/components/product-search-dialog.component';
import { ProductPurchaseNewDialogComponent } from '../products/components/product-purchase-new-dialog.component';
import { SupplierRemoveDialogComponent } from '../suppliers/supplier-remove-dialog.component';
import { ListSelectDialogComponent } from '@frontend/shared/components/list-select-dialog.component';
import { PaymentDialogComponent } from '@frontend/shared/components/payment-dialog.component';
import { IframeDialogComponent } from '@frontend/shared-dialogs/components/iframe-dialog.component';
import { YesNoDialogComponent } from '@frontend/shared/components/yes-no-dialog.component';
import { CanvasSignDialogComponent } from './components/canvas-sign-dialog.component';
import { AddUserMenuItemDialogComponent } from './components/add-user-menu-item-dialog.component';
import { EditUserMenuItemDialogComponent } from './components/edit-user-menu-item-dialog.component';
import { ItemsRepositionDialogComponent } from './components/items-reposition-dialog.component';
import { FrontendDirectivesModule } from '@frontend/shared/frontend-directives.module';
import { SharedDirectivesModule } from '@proman/shared/directives/shared-directives.module';
import { Fa6Module } from '@proman/fa/fa6.module';
import { PromanColorComponent } from '@proman/color/proman-color.component';
import { PromanChartsModule } from '@proman/charts/charts.module';
import { MenuTreeDialogComponent } from './components/menu-tree-dialog.component';
import { TaxPaymentDialogComponent } from '@frontend/shared/components/tax-payment-dialog.component';
import { MatRadioModule } from "@angular/material/radio";
import { FrontendParametersModule } from '@frontend/frontend-parameters/frontend-parameters.module';
import { PromanAutocompleteComponent } from '@proman/autocomplete/proman-autocomplete.component';
import { PromanParametersModule } from '@proman/parameters/proman-parameters.module';
import { FrontendPipesModule } from '@frontend/shared/pipes/frontend-pipes.module';

const COMPONENTS = [
    PrintTemplateDialogComponent,

    ViewPdfDialogComponent,
    EntityCreateDialogComponent,
    TaxPaymentDialogComponent,
    EntityRemoveDialogComponent,
    EntityEditDialogComponent,
    ActivityLogDialogComponent,
    YesNoDialogComponent,

    WarehouseEditDialogComponent,
    CommissionDialogComponent,
    ProductStorageInfoDialogComponent,

    EmployeeDocumentsReadDialogComponent,

    BarcodePrinterPrintDialogComponent,
    TextPreviewDialogComponent,
    MaterialCreditMaterialsDialogComponent,
    MaterialQuantSearchDialogComponent,
    EmployeeJobParametersEditDialogComponent, // jobs / related events
    ProductContainerRegisterDialogComponent, // products / shipment - maybe movable
    WorkshiftColorsDialogComponent, // table monthly
    MetadataPathBuilderDialogComponent, // dynamic table / notification expression

    ConsumerBookingDialogComponent, // consumer booking / loose relation
    WorkplaceInfoDialogComponent, // workplace info / other productions operations
    WorkgroupSpecialisationRemoveDialogComponent,
    ArticleOperationWorkplaceSelectDialogComponent,
    ProductEditDialogComponent, // orders service (global)
    OrderCopyDialogComponent, // orders service (global)

    EventEditDialogComponent, // event compoennt / order operations / related events
    SaleEventDialogComponent, // from projects-sale events module, crashes there
    ProductCreateDialogComponent,
    OcrImageReadDialogComponent,
    ProductSearchDialogComponent,
    ProductPurchaseNewDialogComponent,

    SupplierRemoveDialogComponent, // called from toolbar
    ListSelectDialogComponent,
    PaymentDialogComponent, // purchase order and payments
    IframeDialogComponent,
    CanvasSignDialogComponent,
    AddUserMenuItemDialogComponent,
    EditUserMenuItemDialogComponent,
    ItemsRepositionDialogComponent,
    MenuTreeDialogComponent,
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        InputsModule,
        Fa6Module,
        PipesModule,
        SharedDirectivesModule,
        FrontendDirectivesModule,
        FrontendParametersModule,
        PromanCommonComponentsModule,
        PromanAutocompleteComponent,
        FrontendListManagerModule,
        SharedComponentsModule,
        MatExpansionModule,
        FlexLayoutModule,
        MatLegacyDialogModule,
        MatLegacyListModule,
        PromanColorComponent,
        PromanChartsModule,
        PromanParametersModule,
        MatRadioModule,
        FrontendPipesModule,
        InputDialogComponent,

    ],
    declarations: COMPONENTS,
    providers: [],
    exports: [
        ...COMPONENTS,
        InputDialogComponent,

    ]
})

export class SharedDialogsModule {}
