import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Entity } from '@proman/services/entity.service';
import { PrinterService } from '@proman/services/printer.service';
import { QueryExpressionService } from '@proman/services/query-expression.service';
import { PromanFile, Template } from '@proman/interfaces/entity-interfaces';
import { FilePreviewService } from '@proman/services/file-preview.service';
import { DocumentEntityInterface } from '@proman/resources/document';
import { TemplateEntityInterface } from '@proman/resources/template';

@Component({
    selector: 'pm-print-template-dialog',
    template: `
        <pro-dialog-title title="select_template"></pro-dialog-title>
        <div mat-dialog-content>
            <ul class="proman-list">
                <li *ngFor="let template of templates"
                    (click)="select(template)">
                    {{ template.name }}
                </li>
            </ul>
        </div>
    `
})

export class PrintTemplateDialogComponent {
    templates: Template[] = [];
    documentEntity: DocumentEntityInterface;
    templateEntity: TemplateEntityInterface;

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        private Printer: PrinterService,
        private Entity: Entity,
        private QueryExpression: QueryExpressionService,
        private dialogRef: MatLegacyDialogRef<PrintTemplateDialogComponent>,
        private FilePreview: FilePreviewService,
    ) {
        this.documentEntity = this.Entity.get('document');
        this.templateEntity = this.Entity.get('template');

        this.templateEntity
            .search({ context: this.QueryExpression.eqStrict(this.data.type), ...data.additionalQuery })
            .then((response: any) => this.templates = response);
    }

    async select(template: Template) {
        let data: any = { template: template.id };
        let htmlPromise: any;
        let file: PromanFile;
        switch (this.data.type) {

            case 'production':
                data.production = this.data.printable?.id || data.template;
                htmlPromise = this.templateEntity.production(data);

                break;

            case 'development':
                data.developmentProject = this.data.printable.id;
                htmlPromise = this.templateEntity.developmentProject(data);

                break;

            case 'bill_of_lading':
                this.data.printable.isShipment ? data.shipment = this.data.printable.id : data.container = this.data.printable.id;
                htmlPromise = this.documentEntity.createBillOfLading(data);

                break;

            case 'bill_of_containers':
                this.data.printable.isShipment ? data.shipment = this.data.printable.id : data.container = this.data.printable.id;
                file = await this.documentEntity.createBillOfContainersFile(data)

                break;

        }
        if (!!file) {
            this.FilePreview.show(file);
        } else {
            this.Printer.print(htmlPromise);
        }
        this.dialogRef.close(1);
        /*if (this.data.type === 'bill_of_containers') {
            window.location.reload();
        }*/
    }
}
