import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Order, Production } from '@proman/interfaces/entity-interfaces';

@Component({
    selector: 'pm-material-credit-materials-dialog',
    template: `
        <pro-dialog-title title="credit_materials"></pro-dialog-title>
        <div mat-dialog-content>
            <pm-credit-materials [order]="data.order"
                                 [production]="data.production"></pm-credit-materials>
        </div>
        <pro-dialog-actions></pro-dialog-actions>
    `
})

export class MaterialCreditMaterialsDialogComponent {

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: { order: Order; production: Production },
        public dialogRef: MatLegacyDialogRef<MaterialCreditMaterialsDialogComponent>
    ) {}

}
