import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Entity } from '@proman/services/entity.service';
import { Employee } from '@proman/interfaces/entity-interfaces';

@Component({
    selector: 'pm-employee-documents-read-dialog',
    template: `
        <pro-dialog-title [disableClose]="disableClose" title="employee_documents"></pro-dialog-title>
        <div mat-dialog-content>
            <h3>{{ 'by_checking_you_confirm_that_you_read_the_document' | translate }}</h3>
            <pm-employee-documents-read [employee]="data.employee" (onReadAll)="enableClose()"></pm-employee-documents-read>
        </div>
        <div mat-dialog-actions>
          <pro-btn (onClick)="dialogRef.close()"
                   class="DialogCloseButton"
                   [theme]="'accent'"
                   [label]="'close'"
                   [disabled]="disableClose"
          ></pro-btn>
        </div>
    `
})

export class EmployeeDocumentsReadDialogComponent {
    disableClose: boolean = true;

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: { employee: Employee },
        Entity: Entity,
        public dialogRef: MatLegacyDialogRef<EmployeeDocumentsReadDialogComponent>
    ) {
        dialogRef.disableClose = true;
    }

    enableClose() {
        this.dialogRef.disableClose = false;
        this.disableClose = false;
    }
}
