import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';

@Component({
    selector: 'pm-list-select-dialog',
    template: `
        <pro-dialog-title title="select"></pro-dialog-title>
        <div mat-dialog-content fxLayout="column">

            <mat-list>
                <mat-list-item *ngFor="let item of data.data" class="Clickable" (click)="select(item)">
                    <h4 mat-line>{{ item.name | translate }} | {{ item.quantity }}</h4>
                </mat-list-item>
            </mat-list>

        </div>
        <pro-dialog-actions></pro-dialog-actions>
    `
})

export class ListSelectDialogComponent {

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: { data: any[] },
        private dialogRef: MatLegacyDialogRef<ListSelectDialogComponent>,
    ) {

    }

    select(item: any) {
        this.dialogRef.close(item);
    }

}
