import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Entity } from '@proman/services/entity.service';
import { Article, Product, ProductParameter } from '@proman/interfaces/entity-interfaces';
import { ProductEntityInterface } from '@proman/resources/product';
import { PromanStateService } from '@frontend/shared/services/proman-state.service';
import { isDefinedNotNull, safeMapId } from '@proman/utils';
import { ProductsService } from '../services/products.service';
import { MaterialsService } from '../../materials/services/materials.service';
import { QueryExpressionService } from '@proman/services/query-expression.service';

@Component({
    selector: 'pm-product-purchase-new-dialog',
    template: `
        <pro-dialog-title title="purchase"></pro-dialog-title>
        <div mat-dialog-content fxLayout="column">
            <pm-txt [value]="quantity"
                    [config]="{ label: 'quantity', required: true, parseNumber: true }"
                    (onChange)="setQuantity($event, true)"></pm-txt>
            <pm-txt [value]="packagingQuantity"
                    [config]="{ label: 'packaging_quantity', parseNumber: true }"
                    (onChange)="setPackaging($event, true)"></pm-txt>
            <pro-parameter *ngFor="let parameter of parameters"
                          [config]="{ label: parameter.parameter.name, type: parameter.parameter.type }"
                          [parameter]="parameter"
                          [disabled]="true"></pro-parameter>
        </div>
        <pro-dialog-actions [variant]="'confirm'"
                           [isCallbackDisabled]="isCallbackDisabled"
                           (callback)="confirm()"></pro-dialog-actions>
    `
})

export class ProductPurchaseNewDialogComponent {
    product: Product;
    productEntity: ProductEntityInterface;
    quantity: number;
    packagingQuantity: number;
    article: Article;
    parameters: ProductParameter[];
    isCallbackDisabled: boolean;

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        private Entity: Entity,
        private PromanState: PromanStateService,
        private Products: ProductsService,
        private QueryExpression: QueryExpressionService,
        private Materials: MaterialsService,
        private dialogRef: MatLegacyDialogRef<ProductPurchaseNewDialogComponent>,

    ) {
        this.productEntity = this.Products.productEntity;
        this.product = this.data.product;

        this.productEntity
            .get({ id: this.product.id, join: ['article', 'parameters', 'parameters.parameter', 'vat'] })
            .then((response: Product) => {
                this.article = response.article;
                this.parameters = response.parameters;
            });

        this.quantity = +this.product.storedQuantity;
        this.packagingQuantity = this.product.packagingQuantity || null;
    }

    setQuantity(value: number, expressionUpdate?: boolean) {
        this.quantity = value;

        if (this.article.packagingQuantityExpression && expressionUpdate) {

            this.Products
                .evaluateProductQuantity(this.product, value)
                .then((value: any) => {
                    if (isDefinedNotNull(value)) this.setPackaging(value);
                });

        }

    }

    setPackaging(value: number, expressionUpdate?: boolean) {
        this.packagingQuantity = value;

        if (this.article.storedQuantityExpression && expressionUpdate) {

            this.Products
                .evaluatePackageQuantity(this.product, value)
                .then((value: number) => {
                    if (isDefinedNotNull(value)) this.setQuantity(value);
                });

        }

    }

    confirm() {
        this.isCallbackDisabled = true;
        const product = this.product;

        const presetSupplier = product.suppliers.length === 1 ? product.suppliers[0] : null;

        this.Materials
            .createPurchase('products', {
                supplier: presetSupplier,
                suppliersQuery: product.suppliers.length > 1 ? { id: this.QueryExpression.in(safeMapId(product.suppliers)) } : {}
            })
            .then((purchaseId) => {

                const parameters = this.parameters
                    .map((p: ProductParameter) => ({
                        parameter: p.parameter.id,
                        value: p.value
                    }));

                const request: any = {
                    name: product.name,
                    article: product.article.id,
                    parameters,
                    purchase: purchaseId,
                    parentProduct: product.id,
                    status: this.productEntity.PURCHASE,
                    salePrice: product.salePrice,
                    cost: product.cost,
                    price: product.price
                };

                this.productEntity
                    .create(request)
                    .then((response) => {
                        product.id = response.data;

                        return product;
                    })
                    .then(() => {
                        this.productEntity
                            .update({
                                id: product.id,
                                purchase: purchaseId,
                                storedQuantity: this.quantity,
                                packagingQuantity: this.packagingQuantity,
                                cost: product.cost,
                                price: product.price,
                                salePrice: product.salePrice,
                                vat: product.vat?.id || []
                            })
                    })
                    .then(() => {
                        this.PromanState.to('Purchase', { purchaseId });
                        this.dialogRef.close();
                    });
            });
    }

}
